<template>
  <v-app-bar class="appBar" dense app elevation="0">
    <v-app-bar-nav-icon class="theme--light" @click="toggleDrawer()">
      <v-icon>mdi-menu</v-icon>
    </v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <!-- <v-icon color="blue darken-2"> mdi-autorenew </v-icon> -->
    <h5
      class="mr-6 theme--light blue--text text--darken-2 text-capitalize"
      style="font-size: 16px; font-weight: 400"
      @click="toggleFloor()"
    >
      Swap Floor
    </h5>
    <!-- <v-icon color="blue darken-2"> mdi-magnify-minus-outline </v-icon> -->
    <h5
      class="mr-0 theme--light blue--text text--darken-2 text-capitalize"
      @click="zoomOut()"
      style="font-size: 16px; font-weight: 400"
    >
      Zoom Out
    </h5>
  </v-app-bar>
</template>

<script>
export default {
  name: "BuildingsAppBar",
  data: function () {
    return {
      param: 0,
    };
  },
  methods: {
    toggleDrawer() {
      this.$store.commit("toggleDrawer", ["buildings", true]);
    },
    toggleFloor() {
      this.$store.commit("toggleFloor");
    },
    zoomOut() {
      this.$store.commit("zoomOut");
    },
  },
};
</script>
